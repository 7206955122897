<template>
    <div class="left_items">
        <div style="width: 100%">
            <div>
                <div class="item" :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_ALL}"
                     @click="gameTypeChange(sportsConst.GAME_TYPE_ALL)">
                    <div class="name">전체보기</div>
                </div>
                <div class="item" :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_SOCCER}"
                     @click="gameTypeChange(sportsConst.GAME_TYPE_SOCCER)" v-if="$store.state.currentSportsPage != sportsConst.GAME_DISPLAYPOSITION_SPECIAL">
                    <div class="name"><img src="../../assets/images/icon/football-ico.png">축구</div>
                </div>
                <div class="item"
                     :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_BASKETBALL}"
                     @click="gameTypeChange(sportsConst.GAME_TYPE_BASKETBALL)">
                    <div class="name"><img src="../../assets/images/icon/basketball-ico.png">농구</div>
                </div>
                <div class="item"
                     :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_BASEBALL}"
                     @click="gameTypeChange(sportsConst.GAME_TYPE_BASEBALL)">
                    <div class="name"><img src="../../assets/images/icon/baseball-ico.png">야구</div>
                </div>
                <div class="item" :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_VOLLEYBALL}"
                     @click="gameTypeChange(sportsConst.GAME_TYPE_VOLLEYBALL)">
                    <div class="name"><img src="../../assets/images/icon/volleyball-ico.png">배구</div>
                </div>
                <div class="item" :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_HOCKEY}"
                     @click="gameTypeChange(sportsConst.GAME_TYPE_HOCKEY)">
                    <div class="name"><img src="../../assets/images/icon/hockey-ico.png">하키</div>
                </div>
                <div class="item"
                     :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_USA_FOOTBALL}"
                     @click="gameTypeChange(sportsConst.GAME_TYPE_USA_FOOTBALL)">
                    <div class="name"><img src="../../assets/images/icon/usa-football-ico.png">미식축구</div>
                </div>
<!--                <div class="item" :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_ESPORTS}"-->
<!--                     @click="gameTypeChange(sportsConst.GAME_TYPE_ESPORTS)">-->
<!--                    <div class="name"><img src="../../assets/images/icon/esport-ico.png"> E-SPORTS</div>-->
<!--                </div>-->

            </div>

        </div>
    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {getLeagues, getSportsGameCountGroupByType} from "../../network/sportsRequest";
    import GameTypeSelectorComp from "./GameTypeSelectorComp";
    import {mapGetters} from "vuex"
    import {
        RECEIVE_CHECKED_GAME_TYPE, RECEIVE_CHECKED_LEAUGE, RECEIVE_CHECKED_LEAUGE_DEL, RECEIVE_CHECKED_LEAUGE_DELALL,
    } from "../../store/mutation-types";

    export default {
        name: "SportsLeftBarComp",
        components: {GameTypeSelectorComp},
        props: {
            displayPosition: {
                type: Number,
                default() {
                    return this.$store.state.currentSportsPage;
                }
            },
        },
        data() {
            return {
                leagueList: [],
                checkedLeagueList: [],
                isShowLeagueTab: false,
                sportsConst,
                gameType: 0,
                soccer: 0,
                baseball: 0,
                basketball: 0,
                volleyball: 0,
                football: 0,
                hockey: 0,
                esports: 0,
                tennis: 0,
                alltypecount: 0,
                today:''
            }
        },
        methods: {
            initLeagues() {
                getLeagues({'displayPosition': this.displayPosition}).then(res => {
                    if (res.data.success) {
                        this.leagueList = res.data.data
                        //체크된 리그를 active
                        this.leagueList.forEach(league => {
                            if (this.getSelectedLeagueList.length > 0 && this.getSelectedLeagueList.findIndex((le) => {
                                return league.id === le.id
                            }) !== -1) {
                                this.$set(league, 'checked', true)
                            } else {
                                this.$set(league, 'checked', false)
                            }

                            if (this.getSelectedGameType !== sportsConst.GAME_TYPE_ALL && this.getSelectedGameType === league.gameTypeId) {
                                this.$set(league, 'isShow', true)
                            } else {
                                this.$set(league, 'isShow', false)
                            }
                            if (this.getSelectedGameType === sportsConst.GAME_TYPE_ALL) {
                                this.$set(league, 'isShow', true)
                            }
                            if (this.getSelectedLeagueList.length === 0) {
                                this.$set(league, 'checked', false)
                            }
                        })
                    }
                })
            },
            leagueSelector(item) {
                //체크상태인 리그 삭제
                item.checked = !item.checked
                if (item.checked) {
                    this.$store.commit(RECEIVE_CHECKED_LEAUGE, item)
                } else {
                    //체크상태인 리그 삭제
                    let index = this.getSelectedLeagueList.findIndex((s) => {
                        return s.id === item.id
                    })
                    if (index !== -1) {
                        this.$store.commit(RECEIVE_CHECKED_LEAUGE_DEL, index);
                    }
                }
                if (this.$route.name !== 'sports') {
                    this.$router.push({path: '/sports'})
                }
            },
            gameTypeChange(type) {
                this.$bus.$emit('leftBarHandle',false)
                this.$store.commit(RECEIVE_CHECKED_GAME_TYPE, type)
                this.$store.commit(RECEIVE_CHECKED_LEAUGE_DELALL)
                this.leagueList.forEach(league => {
                    league.checked = false
                    if (type === sportsConst.GAME_TYPE_ALL) {
                        league.isShow = true
                    } else {
                        league.isShow = league.gameTypeId === this.getSelectedGameType;
                    }
                })
                // if (this.$route.name !== 'sports') {
                //     this.$router.push({path: '/sports'})
                //     return false;
                // }
            },
            hideLeagueTab() {
                this.isShowLeagueTab = false;
            }
        },
        computed: {
            ...mapGetters({
                'getSelectedGameType': 'getSelectedGameType',
                'getSelectedLeagueList': 'getSelectedLeagueList',
            }),
            /*인기리그 정리*/
            popularLeagueList() {
                return this.leagueList.filter(leauge => {
                    return leauge.popular === sportsConst.IS_POPULAR_LEAGUE_YES
                });
            },
            /*비인기리그 정리*/
            normalLeagueList() {
                return this.leagueList.filter(leauge => {
                    return leauge.popular === sportsConst.IS_POPULAR_LEAGUE_NOT
                });
            }
        },
        created() {

            getSportsGameCountGroupByType(sportsConst.INPLAY_NOT).then(res => {

                if (res.data.success) {
                    let map = res.data.data;
                    this.soccer = map[sportsConst.GAME_TYPE_SOCCER] !== undefined ? map[sportsConst.GAME_TYPE_SOCCER] : 0
                    this.baseball = map[sportsConst.GAME_TYPE_BASEBALL] !== undefined ? map[sportsConst.GAME_TYPE_BASEBALL] : 0
                    this.basketball = map[sportsConst.GAME_TYPE_BASKETBALL] !== undefined ? map[sportsConst.GAME_TYPE_BASKETBALL] : 0
                    this.volleyball = map[sportsConst.GAME_TYPE_VOLLEYBALL] !== undefined ? map[sportsConst.GAME_TYPE_VOLLEYBALL] : 0
                    this.hockey = map[sportsConst.GAME_TYPE_HOCKEY] !== undefined ? map[sportsConst.GAME_TYPE_HOCKEY] : 0
                    this.football = map[sportsConst.GAME_TYPE_USA_FOOTBALL] !== undefined ? map[sportsConst.GAME_TYPE_USA_FOOTBALL] : 0
                    this.esports = map[sportsConst.GAME_TYPE_ESPORTS] !== undefined ? map[sportsConst.GAME_TYPE_ESPORTS] : 0
                    this.tennis = map[sportsConst.GAME_TYPE_TENNIS] !== undefined ? map[sportsConst.GAME_TYPE_TENNIS] : 0
                    this.alltypecount = this.soccer + this.baseball + this.basketball + this.volleyball + this.hockey + this.football + this.esports + this.tennis
                }
            })

            this.initLeagues()

            this.today = this.$moment().format("yyyy-MM-DD");
        },
        watch: {
            displayPosition() {
                this.initLeagues()
            },
        }
    }
</script>

<style scoped>

</style>