<template>

  <!--베팅카트-->
  <div class="bet_cart">
    <div class="cart_head">
      <div class="title">
        <div style="width: 100%;text-align: center">
          Betting cart
        </div>
      </div>
    </div>
    <div class="cart">
      <div class="sitem" v-for="(item,index) in $store.state.sportsCartItems" :key="item.id">
        <div class="item_name">
<!--          <div class="name" v-if="item.isbonus === sportsConst.NOT">-->
<!--            {{ item.homeTeamName }} <span style="color: deepskyblue">vs</span> {{ item.awayTeamName }}-->
<!--          </div>-->
          <div class="name" v-if="item.isbonus === sportsConst.NOT">
            {{item.startTime|datef('MM월DD일 HH:mm')}}
          </div>
          <div class="name" v-if="item.isbonus === sportsConst.YES">
            보너스 배당
          </div>
          <div class="del" @click="del(index,item)">
            <i class="fa fa-close"></i>
          </div>
        </div>
        <div class="item_info">
          <div class="kind">

          </div>
          <div class="sinfo">
            <div class="sn">
              <div class="s1">
                <span v-if="item.kind ===  sportsConst.GAME_KIND_1X2">승무패</span>
                <span v-if="item.kind === sportsConst.GAME_KIND_HANDICAP">핸디캡</span>
                <span v-if="item.kind ===  sportsConst.GAME_KIND_OVERUNDER">오버언더</span>
              </div>
              <div class="s2" v-if="item.kind === sportsConst.GAME_KIND_1X2">
                <span v-if="item.selectWay === sportsConst.WAY_HOME">{{ item.homeTeamName }}</span>
                <span v-if="item.selectWay === sportsConst.WAY_DRAW && item.isbonus == sportsConst.NOT">무승부</span>
                <span
                    v-if="item.selectWay === sportsConst.WAY_DRAW && item.isbonus == sportsConst.YES">{{ item.drawTeamName }}</span>
                <span v-if="item.selectWay === sportsConst.WAY_AWAY">{{ item.awayTeamName }}</span>
              </div>
              <div class="s2" v-if="item.kind === sportsConst.GAME_KIND_HANDICAP">
                <span
                    v-if="item.selectWay === sportsConst.WAY_HOME">{{ item.homeTeamName }} ({{ item.handicapVal }})</span>
                <span v-if="item.selectWay === sportsConst.WAY_DRAW">무승부</span>
                <span
                    v-if="item.selectWay === sportsConst.WAY_AWAY">{{ item.awayTeamName }} ({{ item.handicapVal * -1 }})</span>
              </div>
              <div class="s2" v-if="item.kind === sportsConst.GAME_KIND_OVERUNDER">
                <span v-if="item.selectWay === sportsConst.WAY_HOME">오버 ({{ item.overunderVal }})</span>
                <span v-if="item.selectWay === sportsConst.WAY_AWAY">언더 ({{ item.overunderVal }})</span>
              </div>
              <div class="s3">
                {{ item.selectOdd }}
              </div>


            </div>

          </div>
        </div>
      </div>
    </div>
    <!--        <div v-if="$store.state.sportsCartItems.length === 0" class="cart_selected_items">-->
    <!--            <div class="item">경기를 선택하세요</div>-->
    <!--        </div>-->
    <div class="info">
      <div class="item">
        <!--                <div class="it">-->
        <!--                    <span class="t1">최대베팅</span>-->
        <!--                    <span class="t2" v-if="displayPosition !== sportsConst.GAME_DISPLAYPOSITION_SPECIAL">{{this.rankConfig.betMaxLimitCash|comma}} 원</span>-->
        <!--                    <span class="t2" v-else>{{this.rankConfig.specBetMaxLimitCash|comma}} 원</span>-->
        <!--                </div>-->
        <!--                <div class="it">-->
        <!--                    <span class="t1">최대적중</span>-->
        <!--                    <span class="t2" v-if="displayPosition !== sportsConst.GAME_DISPLAYPOSITION_SPECIAL">{{this.rankConfig.winMaxLimitCash|comma}} 원</span>-->
        <!--                    <span class="t2" v-else>{{this.rankConfig.specWinMaxLimitCash|comma}} 원</span>-->
        <!--                </div>-->


        <div class="it">
          <span class="t1">배당</span><span class="t2">{{ totalOdd }}</span>
        </div>
        <div class="it" style="box-sizing: border-box;padding-bottom: 5px">
          <span class="t1">베팅금액</span>
          <span class="t2">
                                <input type="text" v-model="betCash" @input="setBetCahsComma" style="width: 100px;"/>
                            </span>
        </div>
        <div class="it" style="">
          <span class="t1">당첨금액</span><span
            class="t2 text-orange">{{ totalWinCash|comma }}</span>
        </div>
        <div class="it" style="line-height: 38px;">
          <span class="t1">보유머니</span><span class="t2">{{ $store.state.userInfo.cash|comma }} 원</span>
        </div>

      </div>
          <div class="money_buttons">
              <button class="" @click="cashAdd(10000)"> 1만</button>
              <button class="" @click="cashAdd(50000)"> 5만</button>
              <button class="" @click="cashAdd(100000)"> 10만</button>
              <button class="" @click="cashAdd(300000)"> 30만</button>
              <button class="" @click="cashAdd(500000)"> 50만</button>
              <button class="" @click="cashAdd(1000000)"> 100만</button>
<!--                <button class="" @click="resetCash()" style="background-color: var(--betcartCashAddBg);">정정하기</button>-->
<!--              <button class="" @click="maxCash()" style="width: 100%;background-color: #954d37">Max Betting</button>-->

          </div>
      <div class="btn_bet">

        <button @click="betNow()" class="btn_betnow">베팅하기</button>
<!--        <button @click="initBetCart()" class="btn_init">전체삭제</button>-->
        <!--                <a href="javascript:void(0)" @click="betNow()">-->
        <!--                    <img src="../../assets/images/right/cart/btn-betnow.png" alt="">-->
        <!--                </a>-->
        <!--                <a href="javascript:void(0)" @click="initBetCart()">-->
        <!--                    <img src="../../assets/images/right/cart/btn-cart-delte-all.png"  alt="">-->
        <!--                </a>-->

      </div>
    </div>
  </div>


</template>

<script>
import {RECEIVE_SPORTS_CART_ITEM_DEL, RECEIVE_SPORTS_CART_ITEM_DEL_ALL} from "../../store/mutation-types";
import sportsConst from "../../common/sportsConst";
import {mapGetters} from 'vuex'
import {logout} from "../../network/userRequest";
import {dobet} from "../../network/sportsBetRequest";
import Clock from "../Clock";
import {checkLoginMinxin} from "../../common/mixin";

export default {
  name: "SportsBetCartComp",
  mixins: [checkLoginMinxin],
  components: {Clock},
  props: {
    displayPosition: {
      type: Number,
      default() {
        return sportsConst.GAME_DISPLAYPOSITION_1x2;
      }
    },
  },
  data() {
    return {
      sportsConst,
      odds: 0,//선택경기 총배당
      winCash: 0,//총당첨금액
      betCash: '',//베팅금액
      betInfo: {
        selectedVals: [],
        betCash: 0
      },
      bonusCash: 0,//보너스 금액
      bonusOdds: 0,//보너스 배당,
      mobileBetCartClose: false,
      locked: false,
      bonusExceptBedang: 1.35,
      crossNeedFolderCount: 3,
    }
  },
  methods: {
    /*배팅카트 아이템 삭제*/
    del(index, item) {
      item.selectWay = sportsConst.WAY_NONE
      item.selectOdd = 1;
      this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL, item)

    },
    /*베팅카드 머니 버튼클릭*/
    cashAdd(amount) {
      if (this.betCash == '') {
        this.betCash = 0
      }
      this.betCash = this.$root.$options.filters.comma(this.getBetCash + parseInt(amount))
    },
    setBetCahsComma() {
      if (this.betCash == '') {
        this.betCash = ''
      } else {
        this.betCash = this.$root.$options.filters.comma(this.betCash)
      }

    },
    resetCash() {
      this.betCash = '';
    },
    /*베팅카드 초기화 ,아이템 전체 삭제*/
    initBetCart() {
      this.betCash = ''
      this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL_ALL)
    },
    /*상한가 베팅*/
    maxCash() {
      if (this.odds <= 1 || this.cartItems.length === 0) return false;
      if (this.displayPosition === sportsConst.GAME_DISPLAYPOSITION_SPECIAL) {
        this.betCash = this.$root.$options.filters.comma(Math.floor((this.rankConfig.specWinMaxLimitCash - 3000) / this.odds))
      } else {
        this.betCash = this.$root.$options.filters.comma(Math.floor((this.rankConfig.winMaxLimitCash - 3000) / this.odds))
      }

    },
    /*베팅하기*/
    betNow() {
      if (!this.checkLogin()) {
        return false
      }
      //선택경기개수 체크
      if (this.cartItems.length === 0) {
        this.$swal({
          title: '경기를 선택하세요',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      //축구 동일경기 크로스 베팅시
      //선택경기개수 체크
      // if (!this.checkCroessBetFolderCount()) {
      //     this.$swal({
      //         title: '동일경기 크로스 베팅은 최소 ' + this.crossNeedFolderCount + '폴더 부터 가능합니다',
      //         type: 'error',
      //         showCancelButton: false,
      //         showConfirmButton: true
      //     })
      //     return false;
      // }

      //단폴더 체크
      if (this.cartItems.length === 1) {
        if (this.getBetCash > this.rankConfig.betOneMaxLimitCash) {

          this.$swal({
            title: '단폴더 최대 베팅금액 : ' + this.$root.$options.filters.comma(this.rankConfig.betOneMaxLimitCash) + ' 원',
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
          return false;
        }
      }
      //두폴더 체크
      if (this.cartItems.length === 2) {
        if (this.getBetCash > this.rankConfig.betTwoMaxLimitCash) {
          this.$swal({
            title: '두폴더 최대 베팅금액 :  ' + this.$root.$options.filters.comma(this.rankConfig.betTwoMaxLimitCash) + ' 원',
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
          return false;
        }
      }
      //최고베당체크
      if (this.odds > this.rankConfig.oddsMaxLimit) {
        this.$swal({
          title: '최대 배당 : ' + this.$root.$options.filters.comma(this.rankConfig.oddsMaxLimit) + ' 배',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      //최소베팅금액
      if (this.getBetCash < this.rankConfig.betMinLimitCash) {
        this.$swal({
          title: '최소 베팅금액 : ' + this.$root.$options.filters.comma(this.rankConfig.betMinLimitCash) + ' 원',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      //최대베팅금액
      if (this.getBetCash > this.rankConfig.betMaxLimitCash) {
        this.$swal({
          title: '최대 베팅금액 :' + this.$root.$options.filters.comma(this.rankConfig.betMaxLimitCash) + ' 원',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      //최대당첨금액
      if (this.totalWinCash > this.rankConfig.winMaxLimitCash) {
        this.$swal({
          title: '최대 당첨금액 :' + this.$root.$options.filters.comma(this.rankConfig.winMaxLimitCash) + ' 원',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }

      let title = this.$root.$options.filters.comma(this.getBetCash) + '원을 베팅하시겠습니까?';
      if (this.cartItems.length == 1
          && this.$store.state.userInfo.rank.betOneWincashPercent
          && this.$store.state.userInfo.rank.betOneWincashPercent < 1
          && this.$store.state.userInfo.rank.betOneWincashPercent > 0) {
        title = '단폴더 베팅은 ' + this.$store.state.userInfo.rank.betOneWincashPercent + '배당 차감됩니다. \r\n\t' + this.$root.$options.filters.comma(this.getBetCash) + '원을 베팅하시겠습니까?';
      }

      //베팅시작
      this.$swal({
        title: title,
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        this.betInfo = {selectedVals: []}
        if (res.value) {
          this.cartItems.forEach((item) => {
            this.betInfo.selectedVals.push(item.id + '-' + item.selectWay + '-' + item.isbonus);
          })
          this.betInfo.betCash = this.getBetCash;
          dobet(this.betInfo).then(res => {
            if (res.data.success) {
              this.$swal({
                title: '베팅성공',
                type: 'success',
                showCancelButton: false,
                showConfirmButton: true
              })
              this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL_ALL)
              //베팅성공시 회원정보를 init
              this.$store.dispatch('actionUserInfo')
              this.betCash = '';
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        }
      })

    },


    hideCart() {
      //모바일경우 베팅카드 숨김기능
      //RightBarComp.vue에서 모니터
      this.$bus.$emit('rightMenueHandle', false)
    },
    cartLockAble() {
      this.locked = !this.locked;
      //카트고정여부
      this.$bus.$emit('cartLockAble', this.locked)
    },
    checkCroessBetFolderCount() {
      if (this.cartItems.length == 1) {
        return true
      }
      let selectFolderCount = this.cartItems.length;
      let groupCode = '0'
      let isSoccerCross = false;
      this.cartItems.map(item => {
        if (item.isbonus === sportsConst.NOT) {
          if (item.groupCode === groupCode) {
            isSoccerCross = true
          }
          if (item.league.gameTypeId === sportsConst.GAME_TYPE_SOCCER) {
            groupCode = item.groupCode;
          }
        }

      });

      //if (!isSoccerCross) return true

      return selectFolderCount >= this.crossNeedFolderCount
    }

  },
  computed: {
    ...mapGetters({
      'cartItems': 'getSportsCartItems',
      'rankConfig': 'getRankConfig',
      'bonusConfig': 'getBonusConfig'
    }),
    /*총배당*/
    totalOdd() {
      return this.odds.toFixed(2)
    },
    /*총당첨*/
    totalWinCash() {
      if (this.betCash != '') {
        return Math.round(this.totalOdd * this.getBetCash)
      } else {
        return 0
      }

    },
    /*캐쉬가 String으로 되여 전부 Integer로 전화*/
    getBetCash() {
      return parseInt(this.$root.$options.filters.replace(this.betCash, ',', ''))
    }
  },
  created() {
    this.betCash = ''
  },
  watch: {
    /*카드아이템이 변경될때마다 체크*/
    "cartItems": {
      deep: true,
      handler: function (newValue) {
        /*총배당을 0으로 설정*/
        this.odds = 0;
        newValue.forEach((item) => {
          if (this.odds === 0) {
            this.odds = parseFloat(item.selectOdd)
          } else {
            this.odds = this.odds * parseFloat(item.selectOdd)
          }
        })
        /*다폴더 보너스 설정*/
        this.initBonus()
      }
    },
    betCash() {
      /*다폴더 보너스 설정*/
      this.initBonus()
    },


  },
}
</script>

<style scoped>
.info {
  background-color: #2b2c30;
  padding: 5px;
}

.cart .sitem {
  width: 100%;
  height: 60px;
  background-color: var(--betCartSitemBg);
  color: #5c5c5c;
  border: 1px solid var(--betCartSitemBorderColor);
  font-weight: 300;
  margin-bottom: 5px;
  border-radius: 0px;
}

.cart .sitem .item_name {
  width: 100%;
  height: 28px;
  border-bottom: 1px solid #252424;
  display: flex;
  justify-content: space-between;
  color: #e9e9e9;
}

.cart .sitem .item_name .name {
  width: 80%;
  line-height: 28px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 5px;
  color: #a6a6a6;
}

.cart .sitem .item_name .del {
  width: 20%;
  line-height: 28px;
  text-align: right;
  padding-right: 3px;
  cursor: pointer;
  color: #ff4d51;
}

.cart .sitem .item_info {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding-left: 5px;
}

.cart .sitem .kind {
  width: 100%;
  line-height: 28px;
  color: #db795c;
  text-align: left;
}

.cart .sitem .sinfo {
  width: 100%;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
}

.cart .sitem .sinfo .sn {
  width: 100%;
  color: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0 2px;

}

.sinfo .sn .s1 {
  width: 20%;
  text-align: left;
  color: #606266;
}

.sinfo .sn .s2 {
  width: 60%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #c6c5c6;

}

.sinfo .sn .s3 {
  width: 20%;
  text-align: right;
  color: #fdfffe;
}

.cart .sitem .sinfo .sodd {
  width: 30%;
  text-align: center;
  color: #c6a753;
}

.bet_cart .btn_bet {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  --padding: 0 10px;

}

.bet_cart .btn_bet .btn_betnow {
  width: 100%;
  height: 40px;
  background-color: #020202;
  border-radius: 5px;
  border: 2px solid #ffffff;
  font-size: 16px;
}

.bet_cart .btn_bet .btn_init {
  width: 100%;
  height: 40px;
  background-color: #c00000;
  margin-top: 10px;
  font-size: 16px;
}

</style>